import React from "react";
import styled from "styled-components";

interface Props {
  qualifier?: string;
  title?: string;
  subtitle?: string[];
  info?: string[];
  info2?: string[][];
  linkText?: string;
  linkSource?: string;
  linkRelative?: boolean;
  positionTop: string;
  long?: boolean;
  tight?: boolean;
}

const StyledBlock = styled.div<{ positionTop: string; long: boolean }>`
  height: ${(props) => (props.long ? "388px" : "230px")};
  width: 230px;
  background-color: white;
  color: black;
  position: absolute;
  padding: 24px 24px;
  font-family: ivyora-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  top: ${(props) => props.positionTop};
  @media (min-width: 1024px) {
    width: 400px;
  }
`;

const Qualifier = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;
const Title = styled.p`
  font-size: 24px;
  margin: 0;
  padding: 0;
  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  font-size: 15px;
  font-style: italic;
  margin: 0;
  padding: 0;
  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const Info = styled.p<{ tight: boolean }>`
  font-size: ${(props) => (props.tight ? "15px" : "16px")};
  line-height: ${(props) => (props.tight ? "19px" : "inherit")};
  margin: 0;
  padding: 0;
`;

const InfoLink = styled.a<{ tight: boolean }>`
  font-size: ${(props) => (props.tight ? "15px" : "16px")};
  line-height: ${(props) => (props.tight ? "19px" : "inherit")};
  margin: 0;
  padding: 0;
  color: #000000;
  &:hover {
    background-color: #f0b698;
  }
  display: block;
  width: max-content;
`;

const InfoWrapper = styled.div`
  padding: 12px 0;
`;

const Link = styled.a`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #094017;
  &:hover {
    background-color: #f0b698;
  }
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const InfoBlock: React.FC<Props> = ({
  qualifier,
  title,
  subtitle,
  info,
  info2,
  linkText,
  linkSource,
  linkRelative = false,
  positionTop,
  long = false,
  tight = false,
}) => {
  const linkTarget = linkRelative ? "_self" : "_blank";

  return (
    <StyledBlock positionTop={positionTop} long={long} className="animated">
      {qualifier && <Qualifier>{qualifier}</Qualifier>}
      {title && <Title>{title}</Title>}
      {subtitle &&
        subtitle.map((entry, index) => (
          <Subtitle key={index}>{entry}</Subtitle>
        ))}
      <InfoWrapper>
        {info &&
          info.map((entry, index) => (
            <Info tight={tight} key={index}>
              {entry}
            </Info>
          ))}
      </InfoWrapper>
      {linkText && (
        <Link href={linkSource} target={linkTarget} rel="noopener noreferrer">
          {linkText}
        </Link>
      )}
      <InfoWrapper>
        {info2 &&
          info2.map((entry, index) => {
            if (entry.length > 1) {
              return (
                <InfoLink
                  tight={tight}
                  key={index}
                  href={entry[1]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {entry[0]}
                </InfoLink>
              );
            } else {
              return (
                <Info tight={tight} key={index}>
                  {entry}
                </Info>
              );
            }
          })}
      </InfoWrapper>
    </StyledBlock>
  );
};
