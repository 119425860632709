import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import { NavBanner } from "./components/NavBanner";
import { Footer } from "./components/Footer";
import { Homepage } from "./homepage/index";
import { Event } from "./event/index";
import { Party } from "./party/index";
import { Wip } from "./wip/index";

const App = () => {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <NavBanner />
          <Routes>
            <Route path="/" element={<Homepage text="Welcome" />} />
            <Route path="/Event" element={<Event text="Event Details" />} />
            <Route path="/Party" element={<Party text="Wip" />} />
            <Route path="/Wip" element={<Wip text="Wip" />} />
          </Routes>
          <Footer />
        </header>
      </div>
    </Router>
  );
};

export default App;
