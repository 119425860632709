import styled from "styled-components";

const LinkButton = styled.a`
  font-family: ivyora-display, sans-serif;
  font-size: 18px;
  padding: 10px 12px 14px;
  width: 80px;
  text-align: center;
  color: #ffffff;
  background-color: #204c35;
  margin: 51px;
  @media (min-width: 1024px) {
    font-size: 24px;
    padding: 14px 16px 18px;
  }
`;

export const RsvpCta = () => {
  return (
    <LinkButton
      href={`https://www.zola.com/registry/kyuandcass`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Registry
    </LinkButton>
  );
};
