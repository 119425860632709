import styled from "styled-components";

const StyledFooter = styled.div`
  height: 125px;
  width: 100%;
  background-color: #204c35;
  font-family: ivyora-display, sans-serif;
  color: #ffffff;
  position: relative;
`;

const LinkContainer = styled.div`
  padding: 20px 30px 0 30px;
  display: flex;
  flex-direction: column;
`;

const LinkPairs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Link = styled.a`
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #ffffff;
`;

const TM = styled.p`
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin: -16px 0 0 0;
`;

export const Footer = () => {
  const homePageUrl = window.location.origin;
  const eventPageUrl = `${homePageUrl + "/event"}`;
  const partyPageUrl = `${homePageUrl + "/party"}`;
  const rsvpPageUrl = `https://www.zola.com/wedding/kyuandcass/rsvp`;
  const registryUrl = `https://www.zola.com/registry/kyuandcass`;

  return (
    <StyledFooter>
      <LinkContainer>
        <LinkPairs>
          <Link href={eventPageUrl} target="_self">
            Travel & Stay
          </Link>
          <Link href={partyPageUrl} target="_self">
            Wedding Party
          </Link>
        </LinkPairs>
        <LinkPairs>
          <Link href={registryUrl} target="_blank" rel="noopener noreferrer">
            Registry
          </Link>
          <Link href={rsvpPageUrl} target="_blank" rel="noopener noreferrer">
            RSVP
          </Link>
        </LinkPairs>
      </LinkContainer>
      <p></p>
      <TM>Made by Cass</TM>
    </StyledFooter>
  );
};
