import React from "react";
import styled from "styled-components";

interface Props {
  src1: string;
  alt1: string;
  src2: string;
  alt2: string;
  src3: string;
  alt3: string;
  reverse: boolean;
}

const PhotoSpotlight = styled.img`
  width: 264px;
  height: 264px;
  object-fit: cover;
  object-position: top;
  margin-right: 8px;
  z-index: 100;
  position: absolute;
  top: 302px;
`;

const LeftSpotlightImage = styled(PhotoSpotlight)<{ reverse: boolean }>`
  position: unset;
  margin: ${(props) => (props.reverse ? "0 0 0 40px" : "0 40px 0 0 ")};
`;
const RightSpotlightImage = styled(PhotoSpotlight)<{ reverse: boolean }>`
  position: unset;
  margin: ${(props) =>
    props.reverse ? "-16px 40px -16px 0 " : "-16px 0 -16px 40px"};
  z-index: 150;
`;

const PhotoWrapper = styled.div`
  height: 870px;
  display: flex;
  flex-direction: column;
`;

export const TriPhotoSpotlight: React.FC<Props> = ({
  src1,
  alt1,
  src2,
  alt2,
  src3,
  alt3,
  reverse,
}) => {
  return (
    <PhotoWrapper>
      <LeftSpotlightImage src={src1} alt={alt1} reverse={reverse} />
      <RightSpotlightImage src={src2} alt={alt2} reverse={reverse} />
      <LeftSpotlightImage src={src3} alt={alt3} reverse={reverse} />
    </PhotoWrapper>
  );
};
