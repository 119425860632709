import { useState } from "react";
import styled from "styled-components";

import liljaMaviCass from "../photos/WeddingParty/LiljaMaviCass.jpg";
import saiKyu from "../photos/WeddingParty/Sai&Kyu.jpg";
import saiKyuSaibday from "../photos/WeddingParty/Sai&KyuSaiBday.png";
import willKyuTrip from "../photos/WeddingParty/Will&KyuTrip.jpg";
import willKyuSledding from "../photos/WeddingParty/WillKyuSledding.jpeg";
import fourth from "../photos/WeddingParty/julyfourth2017.jpg";
import brittCassTwo from "../photos/WeddingParty/Britt&Cass2.jpg";
import brittCassYoung from "../photos/WeddingParty/BrittCassYoung.jpg";
import brittKyu from "../photos/WeddingParty/Britt&Kyu.png";
import brothers from "../photos/WeddingParty/Brothers.png";
import dweedle from "../photos/WeddingParty/Dweedle.png";
import harrys from "../photos/WeddingParty/Harry's.png";
import ilyasParty from "../photos/WeddingParty/IlyasParty.jpg";
import kyleCassYoung from "../photos/WeddingParty/KyleCassYoung.jpg";
import kyleKyu from "../photos/WeddingParty/KyleKyu.jpg";
import kyuAtAlis from "../photos/WeddingParty/KyuAli'sWedding.png";
import kyusParty from "../photos/WeddingParty/KyusParty2.jpg";
import parlorGkids from "../photos/WeddingParty/ParlorGeneralsKids.jpeg";
import parlorGyoung from "../photos/WeddingParty/ParlorGeneralsYoung.jpeg";
import springFling from "../photos/WeddingParty/SpringFling.jpeg";
import liljaPark from "../photos/WeddingParty/liljapark.png";
import brittCassMatching from "../photos/WeddingParty/BrittCassMatching.jpeg";
import firstShow from "../photos/WeddingParty/AlBrittCassShow2.jpeg";
import beachesShow from "../photos/WeddingParty/AllBrittCassBeaches.png";
import met from "../photos/WeddingParty/AlCassMet.jpeg";
import alcass from "../photos/WeddingParty/Ally&Cass.jpg";
import aprilalcass from "../photos/WeddingParty/AprilAlCass.jpeg";
import kyleCassLol from "../photos/WeddingParty/kyleandCasslol.jpeg";
import teendweedle from "../photos/WeddingParty/TeenDweedle.jpeg";
import saiCassKyu from "../photos/WeddingParty/SaisBday.jpg";
import cfamily from "../photos/WeddingParty/CFamily.jpg";
import qfamily from "../photos/WeddingParty/QFamilyBK.png";
import moms from "../photos/WeddingParty/Moms.png";
import thanksgiving from "../photos/WeddingParty/Thanksgiving.png";
import parents from "../photos/WeddingParty/Parents.png";
import family from "../photos/WeddingParty/Family.png";
import friendsLiljasBday from "../photos/WeddingParty/friendsLiljasBday.png";
import friendsgiving from "../photos/WeddingParty/friendsgiving.png";
import boys from "../photos/WeddingParty/Boys.jpg";
import ApeAlB from "../photos/WeddingParty/ApeAlB.png";
import AliJoeyKyu from "../photos/WeddingParty/AliJoeyKyu.png";
import CBday from "../photos/WeddingParty/C18Bday.png";
import CasCarLil from "../photos/WeddingParty/CasCarlieLilja.png";
import WillKyuPolaroid from "../photos/WeddingParty/WillKyuPolaroid.png";
import KyuandCass from "../photos/WeddingParty/Kyu&Cass.png";
import lanternFlies from "../photos/WeddingParty/000012210001.jpg";
import dweedleDiner from "../photos/WeddingParty/000012220001.jpg";
import barbeque from "../photos/WeddingParty/000012220011.jpg";
import dominos from "../photos/WeddingParty/000012220015.jpg";
import willsBday from "../photos/WeddingParty/000012220020.jpg";
import grandmas from "../photos/WeddingParty/000012220031.jpg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CTAPhoto = styled.img`
  max-height: 300px;
  max-width: 300px;
  min-width: 265px;
  object-fit: cover;
  object-position: top;
  @media (min-width: 768px) {
    height: inherit;
    max-height: 500px;
    max-width: 750px;
  }
`;

const ArrowButton = styled.button`
  width: 30px;
  height: 30px;
  font-size: large;
  background-color: #204c35;
  color: white;
  margin: 0 -10px;
  z-index: 10;
`;

export const FriendPhotoSlides = () => {
  const [photoNumber, setPhotoNumber] = useState(0);

  const friendPhotos = {
    0: {
      src: liljaMaviCass,
      alt: "Lilja and Cassie on either side of Mavi",
    },
    1: {
      src: willKyuSledding,
      alt: "Will and Kyu sledding as kids",
    },
    2: {
      src: willKyuTrip,
      alt: "Will and Kyu on a trip",
    },
    3: {
      src: lanternFlies,
      alt: "Kyu and Will both coincidentally decided to be lantern flies for Halloween",
    },
    4: {
      src: fourth,
      alt: "Cassie, Will, Ilya, and Kyu on the fourh of july 2017",
    },
    5: {
      src: brittCassYoung,
      alt: "Britt and Cass in highschool",
    },
    6: {
      src: brittCassMatching,
      alt: "Britt and Cass after confirmation wearing the same dress",
    },
    7: {
      src: brittCassTwo,
      alt: "Britt and Cass at our engagment celebration",
    },
    8: {
      src: brittKyu,
      alt: "Britt and Kyu celebrating their birthdays",
    },
    9: {
      src: kyuAtAlis,
      alt: "Kyu, Joe, Will, Phil, and Ehab, at Ali's Wedding",
    },
    10: {
      src: harrys,
      alt: "William, Ilya, Ali, Dee, and Cassie in front of the statue of liberty",
    },
    11: {
      src: firstShow,
      alt: "Ally Cassie and Britt at their first show together.",
    },
    12: {
      src: beachesShow,
      alt: "Ally Cassie and Britt at their most recent show Beaches!",
    },
    13: {
      src: saiKyuSaibday,
      alt: "Sai and Kyu outside together",
    },
    14: {
      src: saiKyu,
      alt: "Sai and Kyu at our engagment celebration",
    },
    15: {
      src: saiCassKyu,
      alt: "Sai Cassie and Kyu on Sai's birthday",
    },
    16: {
      src: brothers,
      alt: "Sai, Kyu, Cassie, and Kyle",
    },
    17: {
      src: kyleCassYoung,
      alt: "Kyle and Cassie as kids",
    },
    18: {
      src: kyleCassLol,
      alt: "Kyle and Cassie picking pumpkins",
    },
    19: {
      src: kyleKyu,
      alt: "Kyle and Kyu in a photo cutout board",
    },
    20: {
      src: springFling,
      alt: "Wilson, Kyu, Cassie, and some Binghamton friends before Spring Fling in 2014",
    },
    21: {
      src: parlorGkids,
      alt: "Joe, Ali, Ilya, Will, and Kyu as kids by a piano",
    },
    22: {
      src: parlorGyoung,
      alt: "Kyu, Joe, Ali, Will, and Ilya as kids posing for a band photo",
    },
    23: {
      src: willsBday,
      alt: "Will's Birthday",
    },
    24: {
      src: kyusParty,
      alt: "Kyu's wedding party again'",
    },
    25: {
      src: grandmas,
      alt: "Mavi takes a photo of Lilja, Kyu, Cassie, and Ham outside of Grandmas",
    },
    26: {
      src: dominos,
      alt: "Cassie takes a photo of Lilja, Mavi, and Kyu playing dominos on the balcony",
    },
    27: {
      src: liljaPark,
      alt: "Lilja, Daylon, and Kyu at the park at sunset",
    },
    28: {
      src: liljaPark,
      alt: "Lilja, Daylon, and Kyu at the park at sunset",
    },
    29: {
      src: aprilalcass,
      alt: "April, Ally, and Cass on their last day of senior year",
    },
    30: {
      src: met,
      alt: "Al and Cass immitating art at the Met",
    },
    31: {
      src: alcass,
      alt: "Al and Cass at our engagment celebration",
    },
    32: {
      src: teendweedle,
      alt: "Dweedle in target",
    },
    33: {
      src: dweedle,
      alt: "Ally, April, Britt, and Cassie in the wine cellar of Grand Army",
    },
    34: {
      src: dweedleDiner,
      alt: "Dweedle at their hometown diner",
    },
    35: {
      src: qfamily,
      alt: "Cassie and Kyu's Family out to dinner",
    },
    36: {
      src: cfamily,
      alt: "Kyu and Cassie's Family on a walk",
    },
    37: {
      src: thanksgiving,
      alt: "Sai, Cassie's family, and Grandma, together on Thanksgiving",
    },
    38: {
      src: moms,
      alt: "Masumi and Lynda on the day Cassie picked out a wedding outfit",
    },
    39: {
      src: parents,
      alt: "Kyu and Cassie and their parents",
    },
    40: {
      src: family,
      alt: "Yoshi, Masumi, Lynda, Mark, Sai, Kyle, Kyu, Cassie, all together.",
    },
    41: {
      src: barbeque,
      alt: "A big happy family all together!",
    },
    42: {
      src: AliJoeyKyu,
      alt: "Ali Joey and Kyu on the West Coast",
    },
    43: {
      src: boys,
      alt: "Cole Kyu and Ant at Smorgesburg in matching button downs",
    },
    44: {
      src: CBday,
      alt: "Cassie taking a picture of all their friends with their birthday cake instead of being in the photo",
    },
    45: {
      src: friendsgiving,
      alt: "Kyu, Wilson, Mavi, and Lilja, preparing friendsgiving at Tej's house",
    },
    46: {
      src: friendsLiljasBday,
      alt: "Celia, Will, Ali, and Carlie, with Lilja, at their park birthday",
    },
    47: {
      src: ApeAlB,
      alt: "April, Ally, and Britt, Striking a pose in the street together",
    },
    48: {
      src: ilyasParty,
      alt: "Polaroid of Will, Celia, Emma, Chris, Kyu, Carlie, and Kyle at Ilya's house",
    },
    49: {
      src: CasCarLil,
      alt: "Polaroid of Cass, Carlie, and Lilja",
    },
    50: {
      src: WillKyuPolaroid,
      alt: "Polaroid of Will and Kyu",
    },
    51: {
      src: KyuandCass,
      alt: "Kyu and Cass at Derek & Ally's Wedding",
    },
  };
  const endOfTheLine = Object.keys(friendPhotos).length - 1;

  const handleIncrement = () => {
    setPhotoNumber(photoNumber + 1);
  };
  const handleDecrement = () => {
    setPhotoNumber(photoNumber - 1);
  };

  const handleLoopToStart = () => {
    setPhotoNumber(0);
  };

  const handleLoopToEnd = () => {
    setPhotoNumber(endOfTheLine);
  };

  return (
    <Container>
      <ArrowButton
        onClick={photoNumber === 0 ? handleLoopToEnd : handleDecrement}
      >
        {"<"}
      </ArrowButton>
      <CTAPhoto
        src={friendPhotos[photoNumber as keyof typeof friendPhotos].src}
        alt={friendPhotos[photoNumber as keyof typeof friendPhotos].alt}
      />
      <ArrowButton
        onClick={
          photoNumber === endOfTheLine ? handleLoopToStart : handleIncrement
        }
      >
        {">"}
      </ArrowButton>
    </Container>
  );
};
