import { useState, useEffect } from "react";
import styled from "styled-components";

import photo0 from "../photos/ChangingPartyPhotos/WeddingParty0_LR.jpg";
import photo1 from "../photos/ChangingPartyPhotos/WeddingParty1_LR.jpg";
import photo2 from "../photos/ChangingPartyPhotos/WeddingParty2_LR.jpg";
import photo3 from "../photos/ChangingPartyPhotos/WeddingParty3_LR.jpg";

const RotatingImage = styled.img`
  width: 100%;
  transform: scale(1.25);
`;

export const ChangingPhoto = () => {
  const [count, setCount] = useState(0);
  const [photoSrc, setPhotoSrc] = useState(photo0);

  const photoSources = [photo0, photo1, photo2, photo3]; //TEMPORARY
  const maxCount = photoSources.length - 1;

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setCount(count + 1);
      if (count === maxCount) {
        setCount(0);
        setPhotoSrc(photoSources[count]);
      } else {
        setPhotoSrc(photoSources[count]);
      }
    }, 400);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count]);

  return (
    <RotatingImage
      src={photoSrc}
      alt="Kyu & Cassie's Wedding Party, and Mavi at the center drinking coffee."
    />
  );
};
