import React from "react";
import styled from "styled-components";

interface Props {
  src: string;
  alt: string;
}

export const SpotlightImage = styled.img`
  width: 264px;
  height: 264px;
  object-fit: cover;
  object-position: top;
  margin-right: 8px;
  z-index: 100;
  position: absolute;
  top: 302px;
  @media (min-width: 768px) {
    margin-right: 110px;
  }
  @media (min-width: 1024px) {
    width: 300px;
    height: unset;
    top: 230px;
  }
`;

export const PhotoSpotlight: React.FC<Props> = ({ src, alt }) => {
  // fadeInRight
  return <SpotlightImage src={src} alt={alt} className="animated" />;
};
