import React from "react";
import styled from "styled-components";

import spinningAtTheBeach from "../photos/C&K_SunsetBeach(13of83)(lowres).png";
import background from "../assets/small-twigs.png";
import spotlight from "../photos/C&K_SunsetBeach(57of83)(lowres).png";
import callToAction from "../photos/C&K_ByTheFire_Highlights(31of32)(lowres).png";
import laughingSunset from "../photos/Laughing_SunsetBeach(79of83)(lowres).png";
import logo from "../assets/KyuAndCass_Logo_01_Brighter.png";
import { InfoBlock } from "../components/InfoBlock";
import { PhotoSpotlight } from "../components/PhotoSpotlight";
import { RsvpCta } from "../components/RsvpCta";

interface Props {
  text?: string;
}

const PageWrapper = styled.div`
  background-color: #153722;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
`;

const MobileHeaderImage = styled.img`
  width: 100%;
  height: 850px;
  object-fit: cover;
  object-position: right;
  @media (min-width: 768px) {
    height: fit-content;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

const DesktopImageWrapper = styled.div`
  height: 1025px;
  display: none;
  @media (min-width: 1024px) {
    display: unset;
  }
`;

const DesktopHeaderImage = styled.img`
  width: 100%;
  transform: scale(1.25);
`;

const HeaderOverlay = styled.div`
  color: #ffffff;
  width: 100%;
  height: 144px;
  position: absolute;
  top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  @media (min-width: 1024px) {
    height: 288px;
    font-size: 32px;
  }
`;

const Logo = styled.img`
  width: 300px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 870px;
  object-fit: cover;
`;

const LandingSection = styled.div`
  width: 100%;
  margin: -5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InfoSection = styled.div<{ background: any }>`
  width: 100%;
  margin: -25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media (min-width: 768px) {
    margin-top: -264px;
  }
  @media (min-width: 1200px) {
    margin-top: -150px;
  }
`;

const PhotoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: -32px;
`;

const CallToActionSection = styled.div`
  height: 578px;
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: #faf7f0;
  @media (min-width: 768px) {
    height: 800px;
  }
`;

const CTABlurb = styled.p`
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  width: 264px;
  color: black;
  text-align: center;
  margin-top: 48px;
`;

const CTAPhoto = styled.img`
  height: 250px;
  width: 75%;
  min-width: 265px;
  object-fit: cover;
  object-position: top;
  @media (min-width: 768px) {
    height: inherit;
    max-width: 750px;
  }
`;

export const Homepage: React.FC<Props> = ({ text }) => {
  const handleScroll = () => {
    const isGoodSizeScreen =
      window.screen.availWidth < 400 || window.screen.availWidth > 1024;

    if (isGoodSizeScreen && window.scrollY > 70) {
      document.querySelectorAll("div.animated").forEach((entry) => {
        entry.classList.add("fadeInLeft");
      });
      document.querySelector("img.animated")!.classList.add("fadeInRight");
    }
  };

  window.addEventListener("scroll", handleScroll);

  const eventPageUrl = `${window.location.origin + "/event"}`;

  return (
    <PageWrapper>
      <LandingSection>
        <MobileHeaderImage
          src={spinningAtTheBeach}
          className="Homepage-image"
          alt="kyu and cass spinning at the beach"
        />
        <DesktopImageWrapper>
          <DesktopHeaderImage
            src={laughingSunset}
            className="Homepage-image"
            alt="Kyu and Cass laughing at the beach"
          />
        </DesktopImageWrapper>
        <HeaderOverlay>
          <div>03.09.2024</div>
          <Logo src={logo} alt="Kyu & Cass" />
          <div> We're Getting Married!</div>
        </HeaderOverlay>
      </LandingSection>
      <InfoSection background={background} className="container">
        <InfoBlock
          positionTop="42px"
          qualifier="When"
          title="Saturday, March 9th 2024"
          info={[
            "Ceremony | 6:00pm - 6:30pm",
            "Cocktail | 6:30pm - 7:30pm",
            "Reception | 7:30pm - 11:30pm",
          ]}
          linkText="Full Details +"
          linkSource={eventPageUrl}
          linkRelative={true}
        />
        <BackgroundImage
          src={background}
          className="InfoSection-image"
          alt="background"
        />
        <PhotoWrapper>
          <PhotoSpotlight src={spotlight} alt="photo-spotlight" />
        </PhotoWrapper>
        <InfoBlock
          positionTop="550px"
          qualifier="Where"
          title="The Madison Hotel Conservatory"
          info={["1 Convent Rd", "Morristown, NJ 07960"]}
          linkText="Book your room +"
          linkSource={eventPageUrl}
          linkRelative={true}
        />
      </InfoSection>
      <CallToActionSection>
        <CTABlurb>We hope you'll join us on this special day.</CTABlurb>
        <CTAPhoto src={callToAction} alt="Kyu and Cass roasting marshmallows" />
        <RsvpCta />
      </CallToActionSection>
    </PageWrapper>
  );
};
