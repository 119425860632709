import React from "react";
import styled from "styled-components";

import landing from "../photos/C&K_BKFastFood_Highlight(2of20)(lowres).png";
import background from "../assets/small-twigs.png";
import spotlight from "../photos/Gmap_Madison02.png";
import callToAction from "../photos/C&K_ByTheFire_Highlights(31of32)(lowres).png";
import { InfoBlock } from "../components/InfoBlock";
import { PhotoSpotlight } from "../components/PhotoSpotlight";
import { RsvpCta } from "../components/RsvpCta";

interface Props {
  text?: string;
}

const PageWrapper = styled.div`
  background-color: #153722;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
`;

const MobileHeaderImage = styled.img`
  width: 100%;
  height: 767px;
  object-fit: cover;
  object-position: 56% 50%;
  filter: brightness(0.8);
  @media (min-width: 1024px) {
    display: none;
  }
`;

const DesktopImageWrapper = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: unset;
  }
`;

const DesktopHeaderImage = styled.img`
  width: 100%;
  transform: scale(1.25);
`;

const HeaderOverlay = styled.div`
  color: #ffffff;
  width: 100%;
  height: 144px;
  position: absolute;
  top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  padding-top: 48px;
  text-shadow: 0px 3px 6px #000000;
  @media (min-width: 1024px) {
    height: 288px;
    font-size: 32px;
    padding-top: 0;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 1040px;
  object-fit: cover;
`;

const LandingSection = styled.div`
  width: 100%;
  margin: -5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InfoSection = styled.div<{ background: any }>`
  width: 100%;
  margin: -25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const PhotoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: -32px;
`;

const CallToActionSection = styled.div`
  height: 578px;
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: #faf7f0;
  @media (min-width: 768px) {
    height: 800px;
  }
`;

const CTABlurb = styled.p`
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  width: 264px;
  color: black;
  text-align: center;
  margin-top: 48px;
`;

const CTAPhoto = styled.img`
  height: 250px;
  width: 75%;
  min-width: 265px;
  object-fit: cover;
  object-position: top;
  @media (min-width: 768px) {
    height: inherit;
    max-width: 750px;
  }
`;

export const Event: React.FC<Props> = ({ text }) => {
  const handleScroll = () => {
    const isGoodSizeScreen =
      window.screen.availWidth < 400 || window.screen.availWidth > 1024;

    if (isGoodSizeScreen && window.scrollY > 70) {
      document.querySelectorAll("div.animated").forEach((entry) => {
        entry.classList.add("fadeInLeft");
      });
      document.querySelector("img.animated")!.classList.add("fadeInRight");
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <PageWrapper>
      <LandingSection>
        <MobileHeaderImage
          src={landing}
          className="Homepage-image"
          alt="Kyu and Cass laughing in the back of Cass's car eating fast food"
        />
        <DesktopImageWrapper>
          <DesktopHeaderImage
            src={landing}
            className="Homepage-image"
            alt="Kyu and Cass laughing in the back of Cass's car eating fast food"
          />
        </DesktopImageWrapper>
        <HeaderOverlay>
          <div>Travel & Stay</div>
        </HeaderOverlay>
      </LandingSection>
      <InfoSection background={background} className="container">
        <InfoBlock
          positionTop="42px"
          qualifier="Travel"
          title="1 Convent Road"
          subtitle={["Morristown, NJ 07960"]}
          info={[
            "If taking public transit, The Madison is located across the road from Convent Station on the Morris & Essex (MNE) Line via the NJ Transit.",
          ]}
          info2={[["If driving, parking is available on site."]]}
          tight={true}
        />
        <BackgroundImage
          src={background}
          className="InfoSection-image"
          alt="background"
        />
        <PhotoWrapper>
          <PhotoSpotlight src={spotlight} alt="photo-spotlight" />
        </PhotoWrapper>
        <InfoBlock
          positionTop="550px"
          long={true}
          tight={true}
          qualifier="Stay"
          title="The Madison Hotel"
          subtitle={["T: (973) 285-1800", "E: info@themadisonhotel.com "]}
          info={[
            "We’ve reserved a block of rooms, for your convenience at The Madison Hotel. You can use our room code “nakamal” to receive a group rate until Jan 16, 2024.",
          ]}
          linkText="Book your room +"
          linkSource="https://bookings.travelclick.com/72769?_ga=2.232287963.558088420.1691288414-886313293.1676407902&adults=1&datein=03/08/2024&dateout=03/10/2024&languageid=1&rooms=1#/accommodation/room"
          info2={[
            ["Here are some alternate options that are also nearby:"],
            [
              "Best Western",
              "https://www.bestwestern.com/en_US/book/hotel-rooms.31016.html?ssob=IN3101643G&checkIn=2024-03-08&checkOut=2024-03-10&rooms=1&adults=1&children=0&cid=IN3101643G%3AIWS%3A31016%3A",
            ],
            [
              "Hyatt House",
              "https://www.hyatt.com/shop/rooms/morxm?location=Hyatt%20House%20Morristown&checkinDate=2024-03-08&checkoutDate=2024-03-10&rooms=1&adults=1&kids=0&rate=Standard#room-page-anchor",
            ],
            [
              "Archer Hotel",
              "https://book.archerhotel.com/?_ga=2.220741464.1150262539.1691292265-446206312.1691291689&adult=1&arrive=2024-03-08&chain=15904&child=0&config=AHCC&configcode=AHCC&currency=USD&depart=2024-03-10&hotel=76198&level=hotel&locale=en-US&rooms=1&theme=AHCT&themecode=AHCT",
            ],
          ]}
        />
      </InfoSection>
      <CallToActionSection>
        <CTABlurb>We hope you'll join us on this special day.</CTABlurb>
        <CTAPhoto src={callToAction} alt="Kyu and Cass roasting marshmallows" />
        <RsvpCta />
      </CallToActionSection>
    </PageWrapper>
  );
};
