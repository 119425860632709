import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import logo from "../assets/KyuAndCass_Logo_01.png";
import hamburger from "../assets/icons8-menu-rounded-100.png";
import closeIcon from "../assets/closeIcon.png";
import graphic from "../assets/CassFloral01.png";

const Banner = styled.div<{ transparencyLevel: number }>`
  font-family: ivyora-display, sans-serif;
  z-index: 1000;
  height: 75px;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 30% 30% 30%;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => `
    rgb(32, 76, 53, ${props.transparencyLevel})`};
  position: fixed;
  top: 0;
`;

const Hamburger = styled.img`
  width: 32px;
`;
const Button = styled.button`
  background: none;
  border: none;
  grid-row-start: 2;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const CloseIcon = styled.img`
  width: 30px;
`;

const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  padding: 0;
`;

const CloseSidebarDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DesktopLeftNav = styled.div`
  display: none;
  @media (min-width: 1024px) {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: 3;
    justify-content: space-evenly;
    display: flex;
  }
`;

const Logo = styled.img`
  width: 175px;
`;

const Link = styled.a`
  color: white;
  grid-row-start: 2;
  @media (max-width: 1024px) {
    margin: 10px 0;
    font-size: 18px;
  }
`;

const LogoLink = styled(Link)`
  grid-column-start: 2;
  grid-column-end: 5;
  justify-self: center;
`;

const RightLink = styled(Link)`
  grid-column-start: 5;
`;

const Sidebar = styled.div`
  background-color: #204c35;
  position: absolute;
  left: 0;
  top: 0;
  width: 180px;
  height: 932px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0.6s;

  &.is-closed {
    transform: translateX(-16em);
  }
`;

const CTAGraphic = styled.img<{ tallPhone: boolean }>`
  width: 100px;
  margin-left: 40px;
  margin-top: ${(props) => (props.tallPhone ? "500px" : "300px")};
`;

export const NavBanner: React.FC = ({}) => {
  const [transparencyLevel, setTransparencyLevel] = useState(0);
  const [sidebarClass, setSidebarClass] = useState("is-closed");
  const mobileNav = useRef<any>(null);
  const tallPhone = window.innerHeight > 800;

  const handleScroll = () => {
    if (window.scrollY < 10) {
      setTransparencyLevel(0);
    } else if (window.scrollY < 25) {
      setTransparencyLevel(0.1);
    } else if (window.scrollY < 50) {
      setTransparencyLevel(0.25);
    } else if (window.scrollY < 75) {
      setTransparencyLevel(0.5);
    } else if (window.scrollY < 100) {
      setTransparencyLevel(0.75);
    } else {
      setTransparencyLevel(1);
    }
  };

  const handleHamburgerClick = () => {
    setSidebarClass("");
  };

  const closeSidebar = () => {
    setSidebarClass("is-closed");
  };

  useEffect(() => {
    let closeHandler = (e: MouseEvent) => {
      if (!mobileNav?.current?.contains(e.target)) {
        closeSidebar();
      }
    };

    document.body.addEventListener("mousedown", closeHandler);

    return () => document.body.removeEventListener("mousedown", closeHandler);
  }, []);

  window.addEventListener("scroll", handleScroll);

  const links = [
    ["Home", window.location.origin, "_self"],
    ["Travel & Stay", window.location.origin + "/event", "_self"],
    ["Wedding Party", window.location.origin + "/party", "_self"],
  ];

  const removeLink = () => {
    switch (window.location.pathname) {
      case "/event":
        links.splice(1, 1);
        break;
      case "/party":
        links.splice(2, 1);
        break;
      case "/":
        links.splice(0, 1);
        break;
    }
  };

  removeLink();

  return (
    <Banner className="Banner" transparencyLevel={transparencyLevel}>
      <Button onClick={handleHamburgerClick}>
        <Hamburger src={hamburger} alt="Hamburger Menu Icon" />
      </Button>
      <DesktopLeftNav>
        {links.map((link) => (
          <Link
            key={link[0]}
            href={link[1]}
            target={link[2]}
            rel="noopener noreferrer"
          >
            {link[0]}
          </Link>
        ))}
      </DesktopLeftNav>

      <Sidebar className={sidebarClass} ref={mobileNav}>
        <CloseSidebarDiv>
          <CloseButton onClick={closeSidebar}>
            <CloseIcon src={closeIcon} alt="Close Icon" />
          </CloseButton>
        </CloseSidebarDiv>
        {links.map((link) => (
          <Link
            key={link[0]}
            href={link[1]}
            target={link[2]}
            rel="noopener noreferrer"
          >
            {link[0]}
          </Link>
        ))}
        <Link
          href={`https://www.zola.com/registry/kyuandcass`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Registry
        </Link>
        <a href={`${window.location.origin}/wip`} target="_self">
          <CTAGraphic
            src={graphic}
            alt="flowers Cass painted"
            tallPhone={tallPhone}
          />
        </a>
      </Sidebar>

      <LogoLink href={window.location.origin}>
        <Logo src={logo} alt="Kyu & Cass" />
      </LogoLink>
      <RightLink
        href={`https://www.zola.com/registry/kyuandcass`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Registry
      </RightLink>
    </Banner>
  );
};
