import React from "react";
import styled from "styled-components";

import peaAndMoose from "../photos/PeaandMooseHoliday2019-2.jpg";

interface Props {
  text?: string;
}

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const ComingSoon = styled.div`
  font-family: ivyora-display, sans-serif;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #204c35;
`;

const ComingSoonImage = styled.img`
  width: 65%;
  margin-top: 20px;
`;

export const Wip: React.FC<Props> = ({ text }) => {
  return (
    <PageWrapper>
      <ComingSoon>
        You found Pea and Moose!
        <ComingSoonImage
          src={peaAndMoose}
          className="Event-image"
          alt="Pea and Moose sitting by a window"
        />
        How Cute!
      </ComingSoon>
    </PageWrapper>
  );
};
