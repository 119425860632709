import React from "react";
import styled from "styled-components";

import { TriPhotoSpotlight } from "../components/TriPhotoSpotlight";
import { ChangingPhoto } from "../components/ChangingPhoto";
import { FriendPhotoSlides } from "../components/FriendPhotoSlides";
import { RsvpCta } from "../components/RsvpCta";

import background from "../assets/small-twigs.png";
import kyusparty from "../photos/WeddingParty/Main/KyusParty.jpg";
import casssparty from "../photos/WeddingParty/Main/CassiesPartyAITake2.png";
import april from "../photos/WeddingParty/Main/April&Cass.png";
import will from "../photos/WeddingParty/Main/Will.jpg";
import wilson from "../photos/WeddingParty/Main/Wilson&Kyu.png";
import kyle from "../photos/WeddingParty/Main/Kyle&Cass.jpg";
import Mavi from "../photos/WeddingParty/MaviOfficiant.png";

interface Props {
  text?: string;
}

const PageWrapper = styled.div`
  background-color: #153722;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
`;

const LandingSection = styled.div`
  width: 100%;
  margin: -5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HeaderOverlay = styled.div`
  color: #ffffff;
  width: 100%;
  height: 144px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  padding-top: 48px;
  text-shadow: 0px 3px 6px #000000;
  @media (min-width: 950px) {
    height: 288px;
    font-size: 32px;
    padding-top: 0;
  }
`;

const PageTitle = styled.div`
  position: relative;
  top: 50%;
`;

const InfoSection = styled.div<{ background: any }>`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 2450px;
  object-fit: cover;
  @media (min-width: 525px) {
    height: 2150px;
  }
`;

const Chunk = styled.div<{ positionTop: string; reverse: boolean }>`
  position: absolute;
  top: ${(props) => (props.reverse ? "1450px" : "450px")};
  text-align: ${(props) => (props.reverse ? "right" : "left")};
  justify-content: space-between;

  @media (min-width: 525px) {
    top: ${(props) => (props.reverse ? "1340px" : "490px")};
    display: flex;
    flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
    flex-wrap: wrap;
    margin: 8px;
  }
`;

const PartyBreakdown = styled.div<{ reverse: boolean }>`
  margin-top: -100px;
  display: flex;
  justify-content: ${(props) => (props.reverse ? "end" : "start")};
  @media (min-width: 525px) {
    width: 50%;
    margin-top: -540px;
  }
`;
const InfoBlock = styled.div`
  height: 100px;
  width: 200px;
  background-color: white;
  color: black;
  padding: 12px 12px 0;
  text-align: left;
  font-size: 18px;
  @media (min-width: 768px) {
    height: 150px;
    width: 300px;
    padding: 20px 24px 0;
    font-size: 24px;
  }
`;

const PartyHeadline = styled.h1`
  text-shadow: 0px 3px 10px #000000;
  @media (min-width: 525px) {
    margin-top: 100px;
  }
`;

const CallToActionSection = styled.div`
  height: 578px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: #faf7f0;
  @media (min-width: 768px) {
    height: 800px;
  }
`;

const CTABlurb = styled.p`
  font-family: ivyora-display, sans-serif;
  font-size: 24px;
  width: 264px;
  color: black;
  text-align: center;
  margin-top: 48px;
`;

const OfficiantSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  heigh: 350px;
`;

const OfficiantTitle = styled(PartyHeadline)`
  @media (min-width: 525px) {
    margin: 24px 0;
  }
`;

const OfficiantInfo = styled(InfoBlock)`
  margin-top: 24px;
  height: 32px;
  width: 128px;
  text-align: center;
  @media (min-width: 525px) {
    height: 48px;
  }
`;

const MavisDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 300px;
`;

const MaviPic = styled.img`
  width: 264px;
  height: 264px;
  object-fit: cover;
  object-position: top;
`;

export const Party: React.FC<Props> = ({ text }) => {
  return (
    <PageWrapper>
      <LandingSection>
        <ChangingPhoto />

        <HeaderOverlay>
          <PageTitle>Wedding Party!</PageTitle>
        </HeaderOverlay>
      </LandingSection>
      <InfoSection background={background} className="container">
        <BackgroundImage
          src={background}
          className="InfoSection-image"
          alt="background"
        />
        <OfficiantSection>
          <OfficiantTitle>Officiant</OfficiantTitle>
          <MavisDiv>
            <MaviPic src={Mavi} alt="placeholder" />
            <OfficiantInfo>Mavi</OfficiantInfo>
          </MavisDiv>
        </OfficiantSection>
        <Chunk positionTop="" reverse={false}>
          <PartyHeadline>Kyu's Party</PartyHeadline>
          <TriPhotoSpotlight
            src1={wilson}
            alt1="Wilson"
            src2={kyusparty}
            alt2="Kyu's Wedding Party"
            src3={will}
            alt3="Will"
            reverse={false}
          />
          <PartyBreakdown reverse={false}>
            <InfoBlock>
              <p>Sai, Ali, Ilya, Joe, Wilson</p>
              <p>Best Man | William</p>
            </InfoBlock>
          </PartyBreakdown>
        </Chunk>
        <Chunk positionTop="1000px" reverse={true}>
          <PartyHeadline>Cass's Party</PartyHeadline>
          <TriPhotoSpotlight
            src1={kyle}
            alt1="Kyle"
            src2={casssparty}
            alt2="Cassie's Wedding Party"
            src3={april}
            alt3="April"
            reverse={true}
          />
          <PartyBreakdown reverse={true}>
            <InfoBlock>
              <p>Kyle, Ally, April, Lilja</p>
              <p>Mate of Honor | Brittany</p>
            </InfoBlock>
          </PartyBreakdown>
        </Chunk>
      </InfoSection>
      <CallToActionSection>
        <CTABlurb>We hope you'll join us on this special day.</CTABlurb>
        <FriendPhotoSlides />
        <RsvpCta />
      </CallToActionSection>
    </PageWrapper>
  );
};
